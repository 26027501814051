<template>
  <div style=" height:100%;overflow-x: hidden;
    overflow-y: auto;">

  <div style="position: sticky;top:0;left:0;">
  <van-nav-bar
  title="标题"
  left-text="返回"
  left-arrow
  @click-left="$router.go(-1)"
/>
  <van-tabs v-model="historyFrom.topPay"  @change="setTopPay">
  <van-tab title="支付记录" :name="0"></van-tab>
  <van-tab title="充值记录" :name="1"></van-tab>
</van-tabs>
    </div>

<van-list
  v-model="loading"
  :finished="finished"
  finished-text="没有更多了"
  @load="onLoad"
>
  <div v-for="(item,index) in payList" :key="index" class="payItem">

    <div class="item"><span class="itenName">商 品 名 ：</span><span class="itemBody">{{item.description}}</span> </div>
    <div class="item"><span class="itenName">购买日期：</span><span class="itemBody">{{item.createTime}}</span></div>
    <div class="item"><span class="itenName">订 单 号 ：</span><span class="itemBody">{{item.outTradeNo}}</span></div>
    <div class="item"><span class="itenName">支付金额：</span><span class="itemBody">￥{{item.moneyNum}}元</span></div>
    <div class="item"><span class="itenName">支付方式：</span><span class="itemBody" v-if="item.payType==0">微信支付</span>
    <span class="itemBody" v-else>余额支付</span></div>
    <div  class="item"><span class="itenName">支付状态：</span>
    <span class="itemBody" v-if="item.payState==1">支付成功</span><span class="itemBody" v-else-if="item.payState==2">支付失败</span></div>
    <div v-if="item.sendState||item.sendState==0" class="item"><span class="itenName">设备状态：</span><span class="itemBody" v-if="item.sendState==0">执行中...</span>
    <span class="itemBody" v-else-if="item.sendState==1">执行成功</span><span class="itemBody" v-else-if="item.sendState==2">执行失败</span></div>

  </div>
</van-list>
  </div>
</template>

<script>
let that;
export default {
  data() {
    return {
    finished: false,
    loading: false,
    total: -1,
      historyFrom: {
        current: 1,
        size: 20,
        wechatOpenId: "",
        
        topPay:0,
      },
      payList: [],
    };
  },
  mounted() {
    that = this;
    that.historyFrom.wechatOpenId = that.$route.query.openId;
  },
  methods: {
    setTopPay(){
        that.payList=[],
        that.loading = true;
        that.finished=false;
        that.getPayLog();
    },
    onLoad(){
        that.loading = true;
        if (that.payList.length==that.total) {
        that.loading = false;
        that.finished=true;
        }else{
        that.getPayLog();
        }
        
    },
    getPayLog() {
        console.log(that.historyFrom);
      that.$api.payParam.getPayLog(that.historyFrom).then((res) => {
          that.loading = false;
        if (res.code == 200) {
            res.data.records.forEach(element => {
                element.moneyNum=(element.moneyNum/100).toFixed(2);
                that.payList.push(element);
            });
          that.total = res.data.total;
          
        }
      });
    },
  },
};
</script>

<style scoped>
*{
    font-size: 15px;
}
.payItem{
    width: 100%;
    border-bottom: 1px solid #ebedf0;
    margin: 0 10px;
    padding: 20px 0;
}
.item{
    display: flex;
    justify-content: left;
    margin: 2px 0;
}
.itenName{
    display: inline-block;
    width: 100px;
    text-align: right;

}
</style>